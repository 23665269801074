var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "router-link",
          { attrs: { to: _vm.sensorRouterLinkTarget(_vm.site.sensor.id) } },
          [_vm._v("\n      " + _vm._s(_vm.sensorName) + "\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c("span", { staticClass: "font-weight-bold mr-2" }, [
        _vm._v(_vm._s(_vm.$t("label.installed_on")) + ":"),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.sensorInstalledOn))]),
    ]),
    _vm._v(" "),
    _vm.sensorSupportsHealthCheck
      ? _c(
          "div",
          [
            _vm.sensor
              ? _c(
                  "div",
                  [
                    _c("sensor-status", {
                      staticClass: "mt-1",
                      attrs: {
                        status: _vm.sensor.health_check_status,
                        canDownload: _vm.admin,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        staticClass: "mt-3",
                        attrs: { justify: "start", "no-gutters": "" },
                      },
                      [
                        _c("span", { staticClass: "font-weight-bold mr-2" }, [
                          _vm._v(_vm._s(_vm.$t("label.last_active")) + ":"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { "data-testid": "sensor_last_active" } },
                          [_vm._v(_vm._s(_vm.sensorLastActive))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("v-progress-circular", {
                  staticClass: "primary--text",
                  attrs: { indeterminate: "", size: 40 },
                }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }