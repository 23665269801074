<template>
  <div v-if="site">
    <page-title-area>
      <entity-header :details="`(${SENSOR_TYPE_NAMES[site.type]})`">
        <template v-if="newRecord">
          {{ $t('label.new') }}
        </template>
        <template v-else>
          {{ site.name }}
        </template>
      </entity-header>
    </page-title-area>
    <entity-form
      :startInEditing="newRecord"
      :on-save="save"
      :on-cancel="cancel"
      data-testid="installation_site_form"
    >
      <template #form="{ editing }">
        <form-group>
          <template #label><span>{{ $t('field.name') }}</span></template>
          <template #input>
            <text-field
              v-model="model.name"
              :rules="rules.name"
              :edit="editing"
            />
          </template>
        </form-group>
        <template v-if="hasThreshold">
          <form-group>
            <template #label><span>{{ $t('field.threshold') }}</span></template>
            <template #input>
              <text-field
                type="number"
                v-model="model.threshold"
                :edit="editing"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.increase_threshold') }}</span></template>
            <template #input>
              <text-field
                type="number"
                v-model="model.increase_threshold"
                :edit="editing"
              />
            </template>
          </form-group>
        </template>
        <form-group>
          <template #label><span>{{ $t('field.memo') }}</span></template>
          <template #input>
            <text-field
              v-model="model.memo"
              :edit="editing"
            />
          </template>
        </form-group>
        <form-group v-if="!admin">
          <template #label><span>{{ $t('field.email_notifications') }}</span></template>
          <template #input>
            <checkbox
              v-model="model.receive_notifications"
              :edit="editing"
            />
          </template>
        </form-group>
        <form-group>
          <template #label><span>{{ $t('field.sensor') }}</span></template>
          <template #input>
            <sensor-select v-model="model.sensor_id" :site="site" v-if="editing" />
            <sensor-installation :site="site" :admin="admin" v-else />
          </template>
        </form-group>
        <form-group v-if="admin">
          <template #label><span>{{ $t('field.organization_name') }}</span></template>
          <template #input>
            <router-link :to="{ name: 'admin-organization', params: { id: site.organization.id } }">
              {{ site.organization.name }}
            </router-link>
          </template>
        </form-group>
      </template>
      <template #buttons="{ editing }">
        <template v-if="!editing">
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-chevron-circle-down"
            fullWidth
            :to="`/activities?siteId=${site.id}`"
          >
            {{ $t('button.show_all', { dataType: dataTypeText }) }}
          </round-button>
        </template>
      </template>
    </entity-form>
  </div>
  <page-load-spinner v-else />
</template>

<i18n lang="yaml">
ja:
  field:
    name: 設置場所名
    threshold: しきい値
    increase_threshold: 増加量のしきい値
    memo: メモ
    email_notifications: メール通知
    sensor: センサー
    organization_name: 組織名

  label:
    new: 設置場所作成
    images: 画像
    videos: 動画
    images_and_videos: 画像/動画

  button:
    show_all: すべての{dataType}を表示

  message:
    save_installation_site_success: 設置場所を保存しました
    save_installation_site_failed: 設置場所の保存に失敗しました

en:
  field:
    name: Name
    threshold: Threshold
    increase_threshold: Increase Threshold
    memo: Notes
    email_notifications: E-mail notifications
    sensor: Sensor
    organization_name: Organization Name

  label:
    new: New Installation Site
    images: Images
    videos: Videos
    images_and_videos: Images and Videos

  button:
    show_all: Show all {dataType}

  message:
    save_installation_site_success: Installation site saved
    save_installation_site_failed: Failed to save installation site
</i18n>

<script>
import Notifications from '@/mixins/notifications';
import SensorTypes, { siteHasCapability } from '@/mixins/sensorTypes';

import EntityForm from '@/components/atoms/EntityForm';
import EntityHeader from '@/components/atoms/EntityHeader';
import PageLoadSpinner from '@/components/atoms/PageLoadSpinner';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import RoundButton from '@/components/atoms/RoundButton';
import SensorStatus from '@/components/organisms/SensorStatus';
import Checkbox from '@/components/Form/Checkbox';
import FormGroup from '@/components/Form/FormGroup';
import TextField from '@/components/Form/TextField';
import Validations from '@/components/Form/mixins/form-validations';

import SensorInstallation from './SensorInstallation';
import SensorSelect from './SensorSelect';

const NEW_SITE_DEFAULT_VALUES = {
  name: '',
  memo: '',
  sensor_id: undefined,
};

export default {
  name: 'installation-site',
  mixins: [
    Notifications,
    SensorTypes,
    Validations,
  ],
  props: {
    admin: Boolean,
    id: String,
    newRecord: Boolean,
    type: String, // 'rat_monitor' | 'insect_monitor_v2' | 'fly' | 'fly_count' | 'fly_scan'
  },
  components: {
    Checkbox,
    EntityForm,
    EntityHeader,
    FormGroup,
    PageLoadSpinner,
    PageTitleArea,
    RoundButton,
    SensorInstallation,
    SensorSelect,
    SensorStatus,
    TextField,
  },
  data() {
    return {
      site: null,
      model: {
        name: '',
        memo: '',
      },
    };
  },
  computed: {
    dataTypeText() {
      const { dataType } = this.SENSOR_DEFINITIONS[this.site.type];
      return this.$t(`label.${dataType}`);
    },
    hasThreshold() {
      return siteHasCapability(this.site, 'threshold');
    },
    rules() {
      return {
        name: [this.required(this.$t('field.name'))],
      };
    },
  },
  mounted: async function () {
    if (this.newRecord) {
      this.site = {
        ...NEW_SITE_DEFAULT_VALUES,
        type: this.type,
      };
    } else {
      this.site = await this.fetchInstallationSite(this.id);
    }
    this.loading = false;
  },
  inject: [
    'fetchInstallationSite',
    'createInstallationSite',
    'updateInstallationSite',
  ],
  methods: {
    buildModel(site) {
      const result = {
        name: site.name,
        memo: site.memo,
        receive_notifications: site.receive_notifications,
      };

      if (this.hasThreshold) {
        result.threshold = site.threshold;
        result.increase_threshold = site.increase_threshold;
      }

      if (site.sensor) {
        result.sensor_id = site.sensor.id;
      }

      return result;
    },
    cancel() {
      if (this.newRecord) {
        this.$router.push('/installation_sites');
        this.model = this.buildModel({});
      } else {
        this.model = this.buildModel(this.site);
      }
    },
    save: async function () {
      try {
        if (this.newRecord) {
          this.site = await this.createInstallationSite({
            ...this.model,
            type: this.type,
          });
          this.$router.replace(`/installation_sites/${this.site.id}`);
        } else {
          this.site = await this.updateInstallationSite(
            this.site.id,
            this.model,
          );
        }
        this.notifySuccess('message.save_installation_site_success');
      } catch (error) {
        this.notifyError('message.save_installation_site_failed');
        throw error;
      }
    },
  },
  watch: {
    site(value) {
      this.model = this.buildModel(value);
    },
  },
};
</script>

<style scoped lang="sass">
</style>
