var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.site
    ? _c(
        "div",
        [
          _c(
            "page-title-area",
            [
              _c(
                "entity-header",
                {
                  attrs: {
                    details: "(" + _vm.SENSOR_TYPE_NAMES[_vm.site.type] + ")",
                  },
                },
                [
                  _vm.newRecord
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("label.new")) +
                            "\n      "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n        " + _vm._s(_vm.site.name) + "\n      "
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("entity-form", {
            attrs: {
              startInEditing: _vm.newRecord,
              "on-save": _vm.save,
              "on-cancel": _vm.cancel,
              "data-testid": "installation_site_form",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "form",
                  fn: function (ref) {
                    var editing = ref.editing
                    return [
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.name"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: {
                                      rules: _vm.rules.name,
                                      edit: editing,
                                    },
                                    model: {
                                      value: _vm.model.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "name", $$v)
                                      },
                                      expression: "model.name",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasThreshold
                        ? [
                            _c("form-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("field.threshold"))
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "input",
                                    fn: function () {
                                      return [
                                        _c("text-field", {
                                          attrs: {
                                            type: "number",
                                            edit: editing,
                                          },
                                          model: {
                                            value: _vm.model.threshold,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "threshold",
                                                $$v
                                              )
                                            },
                                            expression: "model.threshold",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("form-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("field.increase_threshold")
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "input",
                                    fn: function () {
                                      return [
                                        _c("text-field", {
                                          attrs: {
                                            type: "number",
                                            edit: editing,
                                          },
                                          model: {
                                            value: _vm.model.increase_threshold,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "increase_threshold",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.increase_threshold",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.memo"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: { edit: editing },
                                    model: {
                                      value: _vm.model.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "memo", $$v)
                                      },
                                      expression: "model.memo",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      !_vm.admin
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.email_notifications")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("checkbox", {
                                        attrs: { edit: editing },
                                        model: {
                                          value:
                                            _vm.model.receive_notifications,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "receive_notifications",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.receive_notifications",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.sensor"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  editing
                                    ? _c("sensor-select", {
                                        attrs: { site: _vm.site },
                                        model: {
                                          value: _vm.model.sensor_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "sensor_id",
                                              $$v
                                            )
                                          },
                                          expression: "model.sensor_id",
                                        },
                                      })
                                    : _c("sensor-installation", {
                                        attrs: {
                                          site: _vm.site,
                                          admin: _vm.admin,
                                        },
                                      }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _vm.admin
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.organization_name")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "admin-organization",
                                              params: {
                                                id: _vm.site.organization.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.site.organization.name
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "buttons",
                  fn: function (ref) {
                    var editing = ref.editing
                    return [
                      !editing
                        ? [
                            _c(
                              "round-button",
                              {
                                staticClass: "sensor-form__btn my-3",
                                attrs: {
                                  icon: "fa-chevron-circle-down",
                                  fullWidth: "",
                                  to: "/activities?siteId=" + _vm.site.id,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t("button.show_all", {
                                        dataType: _vm.dataTypeText,
                                      })
                                    ) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              117904170
            ),
          }),
        ],
        1
      )
    : _c("page-load-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }