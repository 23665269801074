<template>
  <div>
    <select-field
      :items="items"
      :value="value"
      @input="updateValue"
      edit
    />
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    hoge: ほげ

en:
  label:
    hoge: hoge
</i18n>

<script>
import SelectField from '@/components/Form/SelectField';

export default {
  name: 'sensor-select',
  props: {
    value: String,
    site: Object,
  },
  components: {
    SelectField,
  },
  computed: {
    items() {
      if (this.sensors.length === 0) {
        return [];
      }

      return [
        { text: '', value: '' },
        ...this.sensors.map((sensor) => ({
          text: sensor.name,
          value: sensor.id,
        })),
      ];
    },
    sensors() {
      const currentSensor = this.site.sensor || {};
      const sensors = this.$store.getters.getSensors;

      return sensors.filter((sensor) => (
        (!sensor.installation_site_id || sensor.id === currentSensor.id) &&
        // 'rat_monitor' | 'insect_monitor_v2' | 'fly' | 'fly_count' | 'fly_scan'
        sensor.type === this.site.type &&
        sensor.permissions.includes('full')
      ));
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
  mounted: async function () {
    this.$store.dispatch('requestSensors');
  },
};
</script>

<style scoped lang="sass">
</style>
