<template>
  <div>
    <div>
      <router-link :to="sensorRouterLinkTarget(site.sensor.id)">
        {{ sensorName }}
      </router-link>
    </div>
    <div>
      <span class="font-weight-bold mr-2">{{ $t('label.installed_on') }}:</span>
      <span>{{ sensorInstalledOn }}</span>
    </div>
    <div v-if="sensorSupportsHealthCheck">
      <div v-if="sensor">
        <sensor-status
          :status="sensor.health_check_status"
          :canDownload="admin"
          class="mt-1"
        />
          <v-row justify="start" class="mt-3" no-gutters>
            <span class="font-weight-bold mr-2">{{ $t('label.last_active') }}:</span>
            <span data-testid="sensor_last_active">{{ sensorLastActive }}</span>
          </v-row>
      </div>
      <v-progress-circular indeterminate :size="40" class="primary--text" v-else />
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    installed_on: 設置日
    last_active: 最終接続日時
    no_timestamp: なし

en:
  label:
    installed_on: Installed On
    last_active: Last active
    no_timestamp: Never
</i18n>

<script>
import moment from 'moment';

import { siteHasCapability } from '@/mixins/sensorTypes';
import SensorStatus from '@/components/organisms/SensorStatus';

export default {
  name: 'sensor-installation',
  mixins: [],
  props: {
    admin: Boolean,
    site: Object,
  },
  components: {
    SensorStatus,
  },
  computed: {
    sensor() {
      if (!this.site.sensor) {
        return null;
      }

      return this.$store.getters.getSensor(this.site.sensor.id);
    },
    sensorName() {
      if (!this.site.sensor) {
        return '';
      }

      return this.site.sensor.name;
    },
    sensorInstalledOn() {
      if (!this.site.sensor) {
        return '';
      }

      return moment(this.site.sensor.installed_at).format('YYYY/MM/DD');
    },
    sensorLastActive() {
      if (!this.sensor) {
        return '';
      }

      if (!this.sensor.last_active) {
        return this.$t('label.no_timestamp');
      }

      return moment.unix(this.sensor.last_active).format('YYYY/MM/DD HH:mm');
    },
    sensorSupportsHealthCheck() {
      return siteHasCapability(this.site, 'health_check');
    },
  },
  methods: {
    sensorRouterLinkTarget(sensorId) {
      const routeName = this.admin ? 'admin-sensor' : 'sensor';
      return { name: routeName, params: { id: sensorId } };
    },
  },
  mounted: async function () {
    if (this.site.sensor && this.sensorSupportsHealthCheck) {
      this.$store.dispatch('requestSensor', this.site.sensor.id);
    }
  },
};
</script>

<style scoped lang="sass">
</style>
